<template>
   <div class="partner">
       <div class="product_img">
            <img class="" :src="backImg" alt="">
        </div>
        <div class="partnerText">
                <p>合作伙伴</p>
                <div class="line"></div>
            </div>
        <div class="main partnerWrap">
           <div class="list" v-for="(item,index) in partnerList" :key="index">
               <img :src="item.image" alt="">
           </div>
        </div>
   </div>
</template>

<script>
export default {
   components: {},

    data() {
        return {
            backImg: require('@/assets/images/banner/partenr.jpg'),
            partnerList:[
                {image: require('@/assets/images/partner/partner1.jpg')},
                {image: require('@/assets/images/partner/partner2.png')},
                {image: require('@/assets/images/partner/partner3.png')}
            ]
        };
    },

   computed:{},

   mounted () {},

   methods: {},
}
</script>

<style lang='scss' scoped>
.partner{
    padding-bottom: 50px;
    .partnerText{
        padding:40px 0;
        font-size: 30px;
        text-align: center;
        color:#5972f9c2;
        box-sizing: border-box;
        .line{
            width:100px;
            height:3px;
            background: #5972f9c2;
            margin: 15px auto;
        }
    }
    .partnerWrap{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .list{
        width:360px;
        height: 250px;
        border:1px solid #ccc;
        position: relative;
        &:hover{
            box-shadow: 0px 6px 8px 2px rgba(8, 15, 36, 0.1);
        }
        img{
            position: absolute;
            left:50%;
            top:50%;
            transform: translate(-50%,-50%);
            width:100%;
        }
    }
}
</style>